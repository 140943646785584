import React from 'react'
import styled from 'styled-components'

import { ContentContainer, Row, VerticalLine } from '../components/utils'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { ContactDetails } from '../components/contactDetails'
import { PageTitle } from '../components/common'

const ContactForm = styled.div`
  h2 {
    margin-bottom: 50px;
  }

  h4 {
    span {
      color: #999;
    }
  }

  form {
    p {
      margin-bottom: 10px;
    }

    input,
    textarea,
    button {
      width: 100%;
      font-size: 1.4rem;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 0 0 1px #ddd;
      border: none;
      -webkit-appearance: none;
      margin-bottom: 30px;

      &:focus {
        box-shadow: 0 0 0 2px ${props => props.theme.colors.primary};
        outline: none;
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    textarea {
      height: 200px;
      min-height: 200px;
      max-height: 500px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    button {
      cursor: pointer;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
      border: none;
      box-shadow: none;
      padding: 25px 12px;
      font-size: 1.2rem;
      margin-bottom: 0;
      transition: all 0.25s;

      &:hover {
        background: ${props => props.theme.colors.primaryDark};
      }
    }
  }
`

const ContactPage = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Contact"
      description="Have any questions about our range of massage services or just want to get in touch? We'd love to hear from you!"
    />
    <PageTitle title="Get in Touch" />
    <ContentContainer py="md">
      <Row col={2} gap={100}>
        <ContactForm>
          <form
            name="hamilton-massage-company-contact-form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="hamilton-massage-company-contact-form" />
            <p>Full Name</p>
            <input type="text" name="name" required data-private />
            <p>Email Address</p>
            <input type="email" name="email" required data-private />
            <p>
              Contact Number <span>(Optional)</span>
            </p>
            <input type="phone" name="phone" data-private />
            <p>Your Message</p>
            <textarea name="message" required data-private />
            <button type="submit">Send Message</button>
          </form>
        </ContactForm>
        <VerticalLine bg="rgba(244, 244, 244, 1)">
          <div />
        </VerticalLine>
        <ContactDetails />
      </Row>
    </ContentContainer>
  </Layout>
)

export default ContactPage
